import * as R from 'ramda';
import Environment from 'core/environment';
import { memoizeOneFactory } from 'core/memoizer';
import { BORDER_PROPERTIES, EdgesMatrices } from './type';
const getWeightedStyle = (borderStyles) => {
    const res = {};
    R.addIndex(R.forEach)((rs, i) => {
        if (rs.checksColumn()) {
            return;
        }
        R.forEach(p => {
            const s = rs.style[p] || rs.style.border;
            if (!R.isNil(s)) {
                res[p] = [s, i];
            }
        }, BORDER_PROPERTIES);
    }, borderStyles);
    return res;
};
export default memoizeOneFactory((columns, filtering, borderStyles, listViewStyle) => {
    if (!filtering || columns === 0) {
        return;
    }
    const edges = new EdgesMatrices(1, columns, Environment.defaultEdge, true, !listViewStyle);
    R.forEach(i => R.forEach(j => {
        const cellStyle = getWeightedStyle(borderStyles);
        edges.setEdges(i, j, cellStyle);
    }, R.range(0, columns)), R.range(0, 1));
    return edges;
});
