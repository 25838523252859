import * as R from 'ramda';
export function arrayMap(a1, cb) {
    const mapArray = R.addIndex(R.map);
    return mapArray((iValue, i) => cb(iValue, i), a1);
}
export function arrayMap2(a1, a2, cb) {
    const mapArray = R.addIndex(R.map);
    return mapArray((iValue, i) => cb(iValue, a2[i], i), a1);
}
export function arrayMap3(a1, a2, a3, cb) {
    const mapArray = R.addIndex(R.map);
    return mapArray((iValue, i) => cb(iValue, a2[i], a3[i], i), a1);
}
export function arrayMapN(cb, ...arrays) {
    const a1 = arrays.slice(0, 1);
    const as = arrays.slice(1);
    const mapArray = R.addIndex(R.map);
    return mapArray((iValue, i) => cb(i, [iValue, ...as.map(a => a[i])]), a1);
}
