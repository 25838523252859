import { fieldExpression, stringExpression, valueExpression } from '../lexeme/expression';
import { and } from '../lexeme/logical';
import { contains, dateStartsWith, equal, greaterOrEqual, greaterThan, lessOrEqual, lessThan, notEqual } from '../lexeme/relational';
import { isBool, isEven, isNil, isNum, isObject, isOdd, isPrime, isStr } from '../lexeme/unary';
import { ifExpression, ifLogicalOperator, ifRelationalOperator, ifUnaryOperator, isTerminalExpression } from '.';
const lexicon = [
    {
        ...and,
        if: ifLogicalOperator,
        terminal: false
    },
    ...[contains,
        dateStartsWith,
        equal,
        greaterOrEqual,
        greaterThan,
        lessOrEqual,
        lessThan,
        notEqual
    ].map(op => ({
        ...op,
        if: ifRelationalOperator,
        terminal: false
    })),
    ...[isBool,
        isEven,
        isNil,
        isNum,
        isObject,
        isOdd,
        isPrime,
        isStr
    ].map(op => ({
        ...op,
        if: ifUnaryOperator,
        terminal: true
    })),
    ...[
        fieldExpression,
        stringExpression,
        valueExpression
    ].map(exp => ({
        ...exp,
        if: ifExpression,
        terminal: isTerminalExpression
    }))
];
export default lexicon;
