import isNumeric from 'fast-isnumeric';
import { LexemeType } from 'core/syntax-tree/lexicon';
const FIELD_REGEX = /^{(([^{}\\]|\\.)+)}/;
const STRING_REGEX = /^(('([^'\\]|\\.)+')|("([^"\\]|\\.)+")|(`([^`\\]|\\.)+`))/;
const VALUE_REGEX = /^(([^\s'"`{}()\\]|\\.)+)(?:[\s)]|$)/;
const getField = (value) => value
    .slice(1, value.length - 1)
    .replace(/\\(.)/g, '$1');
export const fieldExpression = {
    present: (tree) => getField(tree.value),
    resolve: (target, tree) => {
        if (FIELD_REGEX.test(tree.value)) {
            return target[getField(tree.value)];
        }
        else {
            throw new Error();
        }
    },
    regexp: FIELD_REGEX,
    subType: 'field',
    type: LexemeType.Expression
};
const getString = (value) => value.slice(1, value.length - 1).replace(/\\(.)/g, '$1');
const getValue = (value) => {
    value = value.match(VALUE_REGEX)[1];
    return isNumeric(value) ?
        +value :
        value.replace(/\\(.)/g, '$1');
};
export const stringExpression = {
    present: (tree) => getString(tree.value),
    resolve: (_target, tree) => {
        if (STRING_REGEX.test(tree.value)) {
            return getString(tree.value);
        }
        else {
            throw new Error();
        }
    },
    regexp: STRING_REGEX,
    subType: 'value',
    type: LexemeType.Expression
};
export const valueExpression = {
    present: (tree) => getValue(tree.value),
    resolve: (_target, tree) => {
        if (VALUE_REGEX.test(tree.value)) {
            return getValue(tree.value);
        }
        else {
            throw new Error();
        }
    },
    regexp: VALUE_REGEX,
    regexpMatch: 1,
    subType: 'value',
    type: LexemeType.Expression
};
