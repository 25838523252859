import * as R from 'ramda';
import React from 'react';
import { memoizeOneFactory } from 'core/memoizer';
import { clearSelection } from 'dash-table/utils/actions';
function deleteRow(idx, data, selectedRows) {
    const newProps = {
        data: R.remove(idx, 1, data),
        // We could try to adjust selection, but there are lots of edge cases
        ...clearSelection
    };
    if (R.is(Array, selectedRows) && R.any(i => i >= idx, selectedRows)) {
        newProps.selected_rows = R.map(
        // all rows past idx have now lost one from their index
        (i) => i > idx ? i - 1 : i, R.without([idx], selectedRows));
        newProps.selected_row_ids = R.map(i => newProps.data[i].id, newProps.selected_rows);
    }
    return newProps;
}
function rowSelectCell(idx, rowSelectable, selectedRows, setProps, data) {
    return (React.createElement("td", { key: 'select', className: 'dash-select-cell', style: { width: `30px`, maxWidth: `30px`, minWidth: `30px` } },
        React.createElement("input", { type: rowSelectable === 'single' ? 'radio' : 'checkbox', name: 'row-select', checked: R.contains(idx, selectedRows), onChange: () => {
                const newSelectedRows = rowSelectable === 'single' ?
                    [idx] :
                    R.ifElse(R.contains(idx), R.without([idx]), R.append(idx))(selectedRows);
                setProps({
                    selected_rows: newSelectedRows,
                    selected_row_ids: R.map(i => data[i].id, newSelectedRows)
                });
            } })));
}
function rowDeleteCell(doDelete) {
    return (React.createElement("td", { key: 'delete', className: 'dash-delete-cell', onClick: () => doDelete(), style: { width: `30px`, maxWidth: `30px`, minWidth: `30px` } }, '×'));
}
const getter = (data, viewportData, viewportIndices, rowSelectable, rowDeletable, selectedRows, setProps) => R.addIndex(R.map)((_, rowIndex) => [
    ...(rowDeletable ? [rowDeleteCell(() => setProps(deleteRow(viewportIndices[rowIndex], data, selectedRows)))] : []),
    ...(rowSelectable ? [rowSelectCell(viewportIndices[rowIndex], rowSelectable, selectedRows, setProps, data)] : [])
], viewportData);
export default memoizeOneFactory(getter);
