import { memoizeOneFactory } from 'core/memoizer';
import { lastPage } from 'dash-table/derived/paginator';
function getNoPagination(data, indices) {
    return { data, indices };
}
function getFrontEndPagination(settings, data, indices) {
    let currentPage = Math.min(settings.current_page, lastPage(data, settings));
    const firstIndex = settings.page_size * currentPage;
    const lastIndex = Math.min(firstIndex + settings.page_size, data.length);
    return {
        data: data.slice(firstIndex, lastIndex),
        indices: indices.slice(firstIndex, lastIndex)
    };
}
function getBackEndPagination(data, indices) {
    return { data, indices };
}
const getter = (pagination_mode, pagination_settings, data, indices) => {
    switch (pagination_mode) {
        case false:
            return getNoPagination(data, indices);
        case true:
        case 'fe':
            return getFrontEndPagination(pagination_settings, data, indices);
        case 'be':
            return getBackEndPagination(data, indices);
        default:
            throw new Error(`Unknown pagination mode: '${pagination_mode}'`);
    }
};
export default memoizeOneFactory(getter);
