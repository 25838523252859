import * as R from 'ramda';
import React from 'react';
import { memoizeOneFactory } from 'core/memoizer';
import { SortDirection } from 'core/sorting';
import multiUpdateSettings from 'core/sorting/multi';
import singleUpdateSettings from 'core/sorting/single';
import * as actions from 'dash-table/utils/actions';
function deleteColumn(column, columns, columnRowIndex, setProps, options) {
    return () => {
        setProps(actions.deleteColumn(column, columns, columnRowIndex, options));
    };
}
function doSort(columnId, sortSettings, sortType, setProps) {
    return () => {
        let direction;
        switch (getSorting(columnId, sortSettings)) {
            case SortDirection.Descending:
                direction = SortDirection.None;
                break;
            case SortDirection.Ascending:
                direction = SortDirection.Descending;
                break;
            case SortDirection.None:
                direction = SortDirection.Ascending;
                break;
            default:
                direction = SortDirection.Ascending;
                break;
        }
        const sortingStrategy = sortType === 'single' ?
            singleUpdateSettings :
            multiUpdateSettings;
        setProps({
            sort_by: sortingStrategy(sortSettings, { column_id: columnId, direction }),
            ...actions.clearSelection
        });
    };
}
function editColumnName(column, columns, columnRowIndex, setProps, options) {
    return () => {
        setProps(actions.editColumnName(column, columns, columnRowIndex, options));
    };
}
function getSorting(columnId, settings) {
    const setting = R.find(s => s.column_id === columnId, settings);
    return setting ? setting.direction : SortDirection.None;
}
function getSortingIcon(columnId, sortSettings) {
    switch (getSorting(columnId, sortSettings)) {
        case SortDirection.Descending:
            return '↓';
        case SortDirection.Ascending:
            return '↑';
        case SortDirection.None:
        default:
            return '↕';
    }
}
function getter(columns, labelsAndIndices, sorting, sortType, sortSettings, paginationMode, setProps, options) {
    return R.addIndex(R.map)(([labels, indices], headerRowIndex) => {
        const isLastRow = headerRowIndex === labelsAndIndices.length - 1;
        return R.addIndex(R.map)(columnIndex => {
            const column = columns[columnIndex];
            const editable = (column.editable_name && R.type(column.editable_name) === 'Boolean') ||
                (R.type(column.editable_name) === 'Number' && column.editable_name === headerRowIndex);
            const deletable = paginationMode !== 'be' &&
                ((column.deletable && R.type(column.deletable) === 'Boolean') ||
                    (R.type(column.deletable) === 'Number' && column.deletable === headerRowIndex));
            return (React.createElement("div", null,
                sorting && isLastRow ?
                    (React.createElement("span", { className: 'sort', onClick: doSort(column.id, sortSettings, sortType, setProps) }, getSortingIcon(column.id, sortSettings))) :
                    '',
                editable ?
                    (React.createElement("span", { className: 'column-header--edit', onClick: editColumnName(column, columns, headerRowIndex, setProps, options) }, `✎`)) :
                    '',
                deletable ?
                    (React.createElement("span", { className: 'column-header--delete', onClick: deleteColumn(column, columns, headerRowIndex, setProps, options) }, '×')) :
                    '',
                React.createElement("span", null, labels[columnIndex])));
        }, indices);
    }, labelsAndIndices);
}
export default memoizeOneFactory(getter);
