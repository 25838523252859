import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
import sort, { defaultIsNully } from 'core/sorting';
import { QuerySyntaxTree } from 'dash-table/syntax-tree';
const getter = (data, filtering, filter, sorting, sort_by = [], sorting_treat_empty_string_as_none) => {
    const map = new Map();
    R.addIndex(R.forEach)((datum, index) => {
        map.set(datum, index);
    }, data);
    if (filtering === 'fe' || filtering === true) {
        const tree = new QuerySyntaxTree(filter);
        data = tree.isValid ?
            tree.filter(data) :
            data;
    }
    const isNully = sorting_treat_empty_string_as_none ?
        (value) => value === '' || defaultIsNully(value) :
        undefined;
    if (sorting === 'fe' || sorting === true) {
        data = sort(data, sort_by, isNully);
    }
    // virtual_indices
    const indices = R.map(datum => map.get(datum), data);
    return { data, indices };
};
export default memoizeOneFactory(getter);
