import * as R from 'ramda';
export function matrixMap(m1, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, i, j), iRow), m1);
}
export function matrixMap2(m1, m2, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, m2 ? m2[i][j] : undefined, i, j), iRow), m1);
}
export function matrixMap3(m1, m2, m3, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, m2 ? m2[i][j] : undefined, m3 ? m3[i][j] : undefined, i, j), iRow), m1);
}
export function matrixMap4(m1, m2, m3, m4, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, m2 ? m2[i][j] : undefined, m3 ? m3[i][j] : undefined, m4 ? m4[i][j] : undefined, i, j), iRow), m1);
}
export function matrixMapN(cb, m1, ...matrices) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(i, j, [
        ijValue,
        m1[i][j],
        ...matrices.map(m => m ? m[i][j] : undefined)
    ]), iRow), m1);
}
