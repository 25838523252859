import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
import { BORDER_PROPERTIES_AND_FRAGMENTS } from '../edges/type';
function getter(columns, columnStyles, data, offset) {
    return R.addIndex(R.map)((datum, index) => R.map(column => {
        const relevantStyles = R.map(s => s.style, R.filter(style => style.matchesColumn(column) &&
            style.matchesRow(index + offset.rows) &&
            style.matchesFilter(datum), columnStyles));
        return relevantStyles.length ?
            R.omit(BORDER_PROPERTIES_AND_FRAGMENTS, R.mergeAll(relevantStyles)) :
            undefined;
    }, columns), data);
}
function opGetter(columns, columnStyles, data, offset) {
    return R.addIndex(R.map)((datum, index) => R.map(_ => {
        const relevantStyles = R.map(s => s.style, R.filter(style => !style.checksColumn() &&
            style.matchesRow(index + offset.rows) &&
            style.matchesFilter(datum), columnStyles));
        return relevantStyles.length ?
            R.omit(BORDER_PROPERTIES_AND_FRAGMENTS, R.mergeAll(relevantStyles)) :
            undefined;
    }, R.range(0, columns)), data);
}
export default memoizeOneFactory(getter);
export const derivedDataOpStyles = memoizeOneFactory(opGetter);
