import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import CellFactory from 'dash-table/components/CellFactory';
import EdgeFactory from 'dash-table/components/EdgeFactory';
import FilterFactory from 'dash-table/components/FilterFactory';
import HeaderFactory from 'dash-table/components/HeaderFactory';
import { clearSelection } from 'dash-table/utils/actions';
const handleSetFilter = (setProps, setState, filter, rawFilterQuery, map) => {
    setProps({ filter, ...clearSelection });
    setState({ workFilter: { map, value: filter }, rawFilterQuery });
};
function filterPropsFn(propsFn, setFilter) {
    const props = propsFn();
    return R.merge(props, { map: props.workFilter.map, setFilter });
}
function getter(cellFactory, filterFactory, headerFactory, edgeFactory) {
    const cells = [];
    const edges = edgeFactory.createEdges();
    const dataCells = cellFactory.createCells(edges.dataEdges, edges.dataOpEdges);
    const filters = filterFactory.createFilters(edges.filterEdges, edges.filterOpEdges);
    const headers = headerFactory.createHeaders(edges.headerEdges, edges.headerOpEdges);
    cells.push(...headers);
    cells.push(...filters);
    cells.push(...dataCells);
    return cells;
}
export default (propsFn) => {
    const setFilter = memoizeOne((setProps, setState) => handleSetFilter.bind(undefined, setProps, setState));
    const cellFactory = new CellFactory(propsFn);
    const filterFactory = new FilterFactory(() => {
        const props = propsFn();
        return filterPropsFn(propsFn, setFilter(props.setProps, props.setState));
    });
    const headerFactory = new HeaderFactory(propsFn);
    const edgeFactory = new EdgeFactory(propsFn);
    return getter.bind(undefined, cellFactory, filterFactory, headerFactory, edgeFactory);
};
