import * as R from 'ramda';
import React from 'react';
import Logger from 'core/Logger';
import { arrayMap, arrayMap2 } from 'core/math/arrayZipMap';
import memoizerCache from 'core/cache/memoizer';
import { memoizeOne } from 'core/memoizer';
import ColumnFilter from 'dash-table/components/Filter/Column';
import { FilteringType } from 'dash-table/components/Table/props';
import derivedFilterStyles, { derivedFilterOpStyles } from 'dash-table/derived/filter/wrapperStyles';
import derivedHeaderOperations from 'dash-table/derived/header/operations';
import { derivedRelevantFilterStyles } from 'dash-table/derived/style';
import { getMultiColumnQueryString } from 'dash-table/syntax-tree';
import { updateMap } from 'dash-table/derived/filter/map';
export default class FilterFactory {
    constructor(propsFn) {
        this.propsFn = propsFn;
        this.filterStyles = derivedFilterStyles();
        this.filterOpStyles = derivedFilterOpStyles();
        this.relevantStyles = derivedRelevantFilterStyles();
        this.headerOperations = derivedHeaderOperations();
        this.onChange = (column, map, setFilter, ev) => {
            Logger.debug('Filter -- onChange', column.id, ev.target.value && ev.target.value.trim());
            const value = ev.target.value.trim();
            map = updateMap(map, column, value);
            const asts = Array.from(map.values());
            const globalFilter = getMultiColumnQueryString(asts);
            const rawGlobalFilter = R.map(ast => ast.query || '', R.filter(ast => Boolean(ast), asts)).join(' && ');
            setFilter(globalFilter, rawGlobalFilter, map);
        };
        this.filter = memoizerCache()((column, index, map, setFilter) => {
            const ast = map.get(column.id.toString());
            return (React.createElement(ColumnFilter, { key: `column-${index}`, classes: `dash-filter column-${index}`, columnId: column.id, isValid: !ast || ast.isValid, setFilter: this.onChange.bind(this, column, map, setFilter), value: ast && ast.query }));
        });
        this.wrapperStyles = memoizeOne((styles, edges) => arrayMap(styles, (s, j) => R.merge(s, edges && edges.getStyle(0, j))));
    }
    get props() {
        return this.propsFn();
    }
    createFilters(filterEdges, filterOpEdges) {
        const { columns, filtering, filtering_type, map, row_deletable, row_selectable, setFilter, style_cell, style_cell_conditional, style_filter, style_filter_conditional } = this.props;
        if (!filtering) {
            return [];
        }
        if (filtering_type === FilteringType.Basic) {
            const relevantStyles = this.relevantStyles(style_cell, style_filter, style_cell_conditional, style_filter_conditional);
            const wrapperStyles = this.wrapperStyles(this.filterStyles(columns, relevantStyles), filterEdges);
            const opStyles = this.filterOpStyles(1, (row_selectable ? 1 : 0) + (row_deletable ? 1 : 0), relevantStyles)[0];
            const filters = R.addIndex(R.map)((column, index) => {
                return this.filter.get(column.id, index)(column, index, map, setFilter);
            }, columns);
            const styledFilters = arrayMap2(filters, wrapperStyles, (f, s) => React.cloneElement(f, {
                style: s
            }));
            const operations = this.headerOperations(1, row_selectable, row_deletable)[0];
            const operators = arrayMap2(operations, opStyles, (o, s, j) => React.cloneElement(o, {
                style: R.mergeAll([
                    filterOpEdges && filterOpEdges.getStyle(0, j),
                    s,
                    o.props.style
                ])
            }));
            return [operators.concat(styledFilters)];
        }
        else {
            return [[]];
        }
    }
}
