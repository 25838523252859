import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
import { BORDER_PROPERTIES_AND_FRAGMENTS } from '../edges/type';
function getter(columns, headerRows, headerStyles) {
    return R.map(idx => R.map(column => {
        const relevantStyles = R.map(s => s.style, R.filter(style => style.matchesColumn(column) &&
            style.matchesRow(idx), headerStyles));
        return relevantStyles.length ?
            R.omit(BORDER_PROPERTIES_AND_FRAGMENTS, R.mergeAll(relevantStyles)) :
            undefined;
    }, columns), R.range(0, headerRows));
}
function opGetter(rows, columns, columnStyles) {
    return R.map(() => R.map(() => {
        const relevantStyles = R.map(s => s.style, R.filter(style => !style.checksColumn(), columnStyles));
        return relevantStyles.length ?
            R.omit(BORDER_PROPERTIES_AND_FRAGMENTS, R.mergeAll(relevantStyles)) :
            undefined;
    }, R.range(0, columns)), R.range(0, rows));
}
export default memoizeOneFactory(getter);
export const derivedHeaderOpStyles = memoizeOneFactory(opGetter);
