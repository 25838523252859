import SyntaxTree from 'core/syntax-tree';
import { LexemeType, boundLexeme } from 'core/syntax-tree/lexicon';
import { ColumnType } from 'dash-table/components/Table/props';
import { fieldExpression } from './lexeme/expression';
import { equal, RelationalOperator } from './lexeme/relational';
import columnLexicon from './lexicon/column';
function getDefaultRelationalOperator(type = ColumnType.Any) {
    switch (type) {
        case ColumnType.Any:
        case ColumnType.Text:
            return RelationalOperator.Contains;
        case ColumnType.Datetime:
            return RelationalOperator.DateStartsWith;
        case ColumnType.Numeric:
            return RelationalOperator.Equal;
    }
}
function isBinary(lexemes) {
    return lexemes.length === 2;
}
function isExpression(lexemes) {
    return lexemes.length === 1 &&
        lexemes[0].lexeme.type === LexemeType.Expression;
}
function isUnary(lexemes) {
    return lexemes.length === 1 &&
        lexemes[0].lexeme.type === LexemeType.UnaryOperator;
}
function modifyLex(config, res) {
    if (!res.valid) {
        return res;
    }
    if (isBinary(res.lexemes) || isUnary(res.lexemes)) {
        res.lexemes = [
            { lexeme: boundLexeme(fieldExpression), value: `{${config.id}}` },
            ...res.lexemes
        ];
    }
    else if (isExpression(res.lexemes)) {
        res.lexemes = [
            { lexeme: boundLexeme(fieldExpression), value: `{${config.id}}` },
            {
                lexeme: boundLexeme(equal),
                value: getDefaultRelationalOperator(config.type)
            },
            ...res.lexemes
        ];
    }
    return res;
}
export default class SingleColumnSyntaxTree extends SyntaxTree {
    constructor(query, config) {
        super(columnLexicon, query, modifyLex.bind(undefined, config));
    }
}
